import React from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"

const PolicyPage = () => {
  return (
    <Layout pageKey="policy">
      <Seo title={"Policy"} />
      <div style={{ padding: "10px" }}>
        <h1>Noe Crafts Website Policy</h1>

        <p>Last Updated: Dec 27, 2023</p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to <b>Noe Crafts</b> <em>(https://noecrafts.com)</em>, a
          unique platform dedicated to promoting and supporting independent
          craft makers. This policy outlines the terms and conditions that
          govern your use of our website and services.
        </p>

        <h2>2. Collaboration and Project Shares</h2>
        <h3>2.1 Collaborative Projects</h3>
        <p>
          At{" "}
          <b>
            <b>Noe Crafts</b>
          </b>
          , we encourage collaboration among indie craft makers. Each project on
          our platform is a collective effort of various contributors, each
          bringing their unique skills and creativity.
        </p>

        <h3>2.2 Shares in Projects</h3>
        <p>
          Contributors to a project receive shares in that specific project.
          These shares represent a stake in the project's success and are a
          testament to each contributor's involvement and contribution.
        </p>

        <h3>2.3 Share Distribution</h3>
        <p>
          The distribution of shares in each project is determined based on the
          level and nature of contribution. This is agreed upon at the start of
          the project between{" "}
          <b>
            <b>Noe Crafts</b>
          </b>{" "}
          and the contributors.
        </p>

        <h2>3. Legal Framework</h2>
        <h3>3.1 Legal Entity</h3>
        <p>
          All legal processes related to the invoicing, financial transactions,
          and administrative processes for collaboration and project shares are
          handled under the legal entity of <b>NoeCrafts OÜ</b>, registered in
          Tallinn, Estonia. <b>NoeCrafts OÜ</b> is responsible for its own tax
          obligations arising from these activities.
        </p>

        <h3>3.2 Tax Obligations</h3>
        <p>
          Contributors and participants in projects are responsible for
          complying with all applicable tax laws and regulations. This includes
          reporting and paying taxes on any income or profits derived from their
          involvement in projects under <b>NoeCrafts OÜ</b>. <b>NoeCrafts OÜ</b>{" "}
          does not assume responsibility for individual tax obligations and
          advises all contributors to seek independent tax advice.
        </p>

        <h3>3.3 Compliance</h3>
        <p>
          <b>NoeCrafts OÜ</b> ensures that all collaborations and share
          distributions comply with the relevant laws and regulations of
          Estonia.
        </p>

        <h3>3.4 Dispute Resolution</h3>
        <p>
          In the event of any disputes arising from the collaboration or share
          distribution, the matter will be addressed under the jurisdiction of
          the competent courts in Estonia.
        </p>

        <h2>4. Use of Website</h2>
        <h3>4.1 Acceptance of Terms</h3>
        <p>
          By using the <b>Noe Crafts</b> website, you agree to comply with this
          policy and all applicable laws.
        </p>

        <h3>4.2 Changes to Policy</h3>
        <p>
          <b>Noe Crafts</b> reserves the right to modify this policy at any
          time. Continued use of our website and services after any such changes
          constitutes your acceptance of the new terms.
        </p>

        <h2>5. Contact Us</h2>
        <p>
          For any questions or concerns regarding this policy, please contact us
          <b> info@noecrafts.com</b>.
        </p>
      </div>
    </Layout>
  )
}

export default PolicyPage
